<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col xm="6" sm="6" lg="5" xl="5" class="register-bg-cc py-5">
        <b-img fluid :src="logoBig" alt="Login Side Image" class="img-max-width" />
        <p class="text-white mt-5 px-5 text-center side-bg-text">A PAMCASH tem uma linha de crédito perfeita para
          financiar o seu frete.</p>
        <b-img fluid :src="auxLogo" alt="Login Side Image" class="img-max-width" />
        <div class="d-flex flex-column justify-content-center align-items-end pt-5">
          <b-img fluid :src="logoFooter" alt="Login Side Image" class="img-max-width" />
        </div>
      </b-col>

      <b-col xm="6" sm="6" lg="7" xl="7" class="d-flex align-items-center auth-bg px-2 py-5 p-lg-5">
        <b-col sm="12" md="12" lg="12" xl="10" class="px-xl-2 mx-auto">

          <b-card-title class="mb-1 font-weight-bold d-flex flex-column" title-tag="h2">
            <span class="text-dark">Criar nova senha</span>
          </b-card-title>
          <b-card-text class="mb-2">
            Digite uma senha forte para proteger a sua conta
          </b-card-text>

          <!-- form -->
          <validation-observer ref="newPasswordForm" #default="{invalid}">
            <b-form class="auth-register-form mt-2">
              <b-row>
                <b-col cols="6">
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="register-password">Crie sua senha</label>
                    </div>
                    <validation-provider #default="{ errors }" name="Senha" vid="password" rules="required">
                      <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                        <b-form-input id="register-password" v-model="password"
                          :state="errors.length > 0 ? false : null" class="form-control-merge"
                          :type="passwordFieldType" name="register-password" placeholder="Password" />
                        <b-input-group-append is-text>
                          <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility('passwordFieldType')" />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="register-password">Confirmação de senha</label>
                    </div>
                    <validation-provider #default="{ errors }" name="Confirmação de senha" vid="confirm_password"
                      rules="required|confirmed:password">
                      <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                        <b-form-input id="register-password-confirmation" v-model="passwordConfirmation"
                          :state="errors.length > 0 ? false : null" class="form-control-merge"
                          :type="passwordConfirmationFieldType" name="register-password-confirmation" placeholder="Password" />
                        <b-input-group-append is-text>
                          <feather-icon class="cursor-pointer" :icon="passwordConfirmToggleIcon"
                            @click="togglePasswordVisibility('passwordConfirmationFieldType')" />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <span> Sua senha precisa conter: </span>
                  <b-form-group class="mt-1">
                    <b-form-checkbox v-model="minimunCaracters" name="checkbox-1" disabled> No mínimo 8 caracteres
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox v-model="capitalLetters" name="checkbox-1" disabled> Letras maiúsculas
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox v-model="smallLetters" name="checkbox-1" disabled> Letras minúsculas
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox v-model="numbers" name="checkbox-1" disabled> Números </b-form-checkbox>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox v-model="symbols" name="checkbox-1" disabled> Simbolos </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col cols="12" class="d-flex justify-content-between">
                  <b-button variant="primary" @click="newPassword" :disabled="!strongPassword && invalid">Salvar nova senha</b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  name: 'ChangePassword',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      canReset: false,
      logoBig: require('@/assets/images/logo/pamcash-logo.svg'),
      auxLogo: require('@/assets/images/logo/pamcash-icon.svg'),
      logoFooter: require('@/assets/images/logo/logo-developedby.svg'),
      password: '',
      passwordConfirmation: '',
      userEmail: '',
      required,
    }
  },
  async mounted() {
    if (!this.$route.params.token)
      return this.$router.push({ name: 'auth-login' })

    try {
      await this.$store.dispatch('common/validateResetToken', this.$route.params.token)
      this.canReset = true
    } catch (error) {
      this.$swal({
        title: 'Erro',
        text: 'Token inválido',
        icon: 'error',
        confirmButtonText: 'Ok',
      }).then(async (result) => {
        if (result.isConfirmed) this.$router.push({name: 'auth-login'})
      })
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordConfirmToggleIcon() {
      return this.passwordConfirmationFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    minimunCaracters() {
      return this.password.length >= 8
    },
    smallLetters() {
      return /[a-z]/.test(this.password)
    },
    capitalLetters() {
      return /[A-Z]/.test(this.password)
    },
    numbers() {
      return /[0-9]/.test(this.password)
    },
    symbols() {
      return /[!@#\$%\^\&*\)\(+=._-]/.test(this.password)
    },
    strongPassword() {
      return this.minimunCaracters && this.smallLetters && this.capitalLetters && this.numbers && this.symbols && this.password === this.passwordConfirmation
    },
  },
  methods: {
    async newPassword() {
      try {
        const payload = {
          token: this.$route.params.token,
          new_password: this.password,
          new_password_confirmation: this.passwordConfirmation,
        }

        this.$swal.showLoading()
        await this.$store.dispatch('auth/newPassword', payload)

        this.$swal({
          title: 'Sucesso!',
          text: 'Senha alterada com sucesso! Faça seu login para continuar.',
          icon: 'success',
          confirmButtonText: 'Logar',
        }).then(async (result) => {
          if (result.isConfirmed) this.$router.push({name: 'auth-login'})
        })

        this.$router.push('auth-login')
      } catch (error) {
        this.$refs.RegisterForm.setErrors(error.response)
      }
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.register-bg-cc {
  background: linear-gradient(180deg, #002B5E 0%, #0070AF 100%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.side-bg-text {
  padding-left: 3rem;
  padding-right: 3rem;
}

.continue-button {
  margin-top: 1.5rem;
}

.img-max-width {
  max-width: 250px
}

@media (min-width: 1679px) {
  .img-max-width {
    max-width: 450px !important;
  }

  .side-bg-text {
    padding-left: 7rem;
    padding-right: 7rem;
  }
}

// #checkbox-terms  + .custom-control-label::before,
// #checkbox-terms + .custom-control-label::after {
//   top: 1.6rem;
// }
</style>
